import React from 'react';
import { NextPage } from 'next';
import Webflow from '../layouts/webflow';
import makeGetStaticPropsForWebflowPage, {
  WebflowPageProps,
} from '../utils/make-get-static-props-for-webflow-page/make-get-static-props-for-webflow-page';
import WebflowRenderer from '../components/webflow-renderer/webflow-renderer.component';
import getWebflowPageUrl from '../utils/get-webflow-page-url/get-webflow-page-url';

const Home: NextPage<WebflowPageProps> = ({ webflowContent }) => (
  <WebflowRenderer webflowContent={webflowContent} />
);

export const getStaticProps = makeGetStaticPropsForWebflowPage({
  url: getWebflowPageUrl('/'),
});

// @ts-ignore
Home.getLayout = (page: React.ReactElement) => <Webflow isHeaderInDarkMode>{page}</Webflow>;

export default Home;
