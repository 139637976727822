import React from 'react';
import Head from 'next/head';
import parseHtml, { DOMNode, Element } from 'html-react-parser';
import { WebflowPageProps } from '../../utils/make-get-static-props-for-webflow-page/make-get-static-props-for-webflow-page';
import getWebflowModifiedLink, {
  WebflowPageItem,
} from '../../utils/get-webflow-modified-link/get-webflow-modified-link';
import WebflowRevalidation from '../weblow-revalidation/webflow-revalidation';
import { clientConfig } from '../../model/config';
import { WEBFLOW_PAGES } from '../../model/constants';
import useLocalizedPath from '../../hooks/use-localized-path/use-localized-path';

interface WebflowRendererProps extends WebflowPageProps {
  additionalPages?: WebflowPageItem[];
}

const WebflowRenderer: React.FC<WebflowRendererProps> = ({ webflowContent, additionalPages }) => {
  const getLocalizedPath = useLocalizedPath();

  const modifyLink = React.useCallback(
    (urlOrPath: string) => {
      const modifiedLink = getWebflowModifiedLink(urlOrPath, {
        webflowSiteUrl: clientConfig.webflowSiteUrl,
        webflowPages: [...(additionalPages || []), ...WEBFLOW_PAGES],
      });
      if (modifiedLink != null) {
        return getLocalizedPath(modifiedLink);
      }
      if (urlOrPath.startsWith('/')) {
        return getLocalizedPath(urlOrPath);
      }
      return urlOrPath;
    },
    [getLocalizedPath, additionalPages]
  );

  const replaceNode = React.useCallback(
    (node: DOMNode) => {
      if (node instanceof Element && node.name === 'a') {
        const { href } = node.attribs;
        if (href) {
          // eslint-disable-next-line no-param-reassign
          node.attribs.href = modifyLink(href);
        }
      }
      return node;
    },
    [modifyLink]
  );

  return (
    <>
      {webflowContent.head && <Head>{parseHtml(webflowContent.head)}</Head>}
      {webflowContent.body && <div>{parseHtml(webflowContent.body, { replace: replaceNode })}</div>}
      <WebflowRevalidation />
    </>
  );
};

export default WebflowRenderer;
