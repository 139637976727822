import parse from 'url-parse';

export interface WebflowPageItem {
  webflowPath: string;
  link: string;
  isFolder?: boolean;
}

export interface GetWebflowModifiedLinkConfig {
  webflowSiteUrl: string;
  webflowPages: WebflowPageItem[];
}

const getWebflowModifiedLink = (
  link: string,
  { webflowPages, webflowSiteUrl }: GetWebflowModifiedLinkConfig
) => {
  const parsed = parse(link, {}, true);

  if (parsed.origin !== 'null' && parsed.origin !== webflowSiteUrl) {
    return null;
  }

  parsed.set('host', '');
  parsed.set('protocol', '');

  const exactMatch = webflowPages.find(item => item.webflowPath === parsed.pathname);
  if (exactMatch != null) {
    parsed.set('pathname', exactMatch.link);
    return parsed.toString();
  }

  const folderMatch = webflowPages.find(
    item => item.isFolder && parsed.pathname.includes(`${item.webflowPath}/`)
  );
  if (folderMatch != null) {
    parsed.set('pathname', parsed.pathname.replace(folderMatch.webflowPath, folderMatch.link));
    return parsed.toString();
  }

  return null;
};

export default getWebflowModifiedLink;
