import React from 'react';
import Analytics from '../components/analytics/analytics';
import Footer from '../components/footer/footer.component';
import Header from '../components/header/header.component';
import ImageViewer from '../components/image-viewer/image-viewer';
import ExternalWidgetHidden from '../components/extrernal-widget-hidden/external-widget-hidden';

type WebflowProps = {
  staticHeaderBackgroundColor?: string;
  isHeaderInDarkMode?: boolean;
  isHeaderHidden?: boolean;
  isFooterHidden?: boolean;
  isExternalWidgetHidden?: boolean;
  children: any;
};

const Webflow: React.FC<WebflowProps> = ({
  children,
  isHeaderInDarkMode,
  staticHeaderBackgroundColor,
  isHeaderHidden,
  isFooterHidden,
  isExternalWidgetHidden,
}) => {
  return (
    <>
      <Analytics />
      {!isHeaderHidden && (
        <Header
          isDarkMode={isHeaderInDarkMode}
          staticHeaderBackgroundColor={staticHeaderBackgroundColor}
        />
      )}
      {children}
      <ImageViewer />
      {!isFooterHidden && <Footer />}
      {isExternalWidgetHidden && <ExternalWidgetHidden />}
    </>
  );
};

export default Webflow;
