import React from 'react';
import { useRouter } from 'next/router';
import { toastr } from 'react-redux-toastr';
import axios from 'axios';
import buildLocalizedPath from '../../utils/build-localized-path/build-localized-path';

const WebflowRevalidation = () => {
  const router = useRouter();

  React.useEffect(() => {
    const { asPath, locales, defaultLocale, query, isReady } = router;
    const { revalidate, ...restQuery } = query;

    if (typeof window !== 'undefined' && isReady && revalidate != null) {
      const revalidatePath = (path: string) => axios.post('/api/revalidate-page', { url: path });

      Promise.all(
        (locales || []).map(locale =>
          revalidatePath(
            buildLocalizedPath({
              path: asPath,
              defaultLocale,
              locale,
            })
          )
        )
      )
        .then(async () => {
          await router.replace({ query: restQuery }, undefined, { shallow: true });
          router.reload();
          toastr.success('Success', 'Page was revalidated');
        })
        .catch(() => {
          toastr.error('Error', 'Can not revalidate page');
        });
    }
  }, [router]);

  return router.query.revalidate != null ? <div className="webflow-revalidation-loader" /> : null;
};

export default WebflowRevalidation;
